import {ParamValue} from '@vaadin/router';
import {createApi} from './polka/api';

export async function getChains(onSuccess: (options: []) => void) {
  await fetch(`assets/data/config.json`)
    .then((r) => r.json())
    .then((data) => {
      onSuccess(data.chains);
    });
}

export async function changeApi(chain: ParamValue, chainOptions: any[]) {
  const rpc = chainOptions.filter(
    (opt: {name: string; rpc: string}) => opt.name === chain.toString()
  )[0].rpc;
  createApi(rpc, () => {});
}
